import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import { createOrganizationSubscription } from 'services/subscription';
import { SubscriptionStatus } from 'constant';
import { Modal } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '24px',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
  py: 5,
  zIndex: 2000,
};

const SubscriptionModel = ({ setShowSubscriptionModal }) => {
  const [modals, setModals] = useState({
    subscribe: true, // Open modal initially
  });

  const [redirecting, setRedirecting] = useState(false); // New state to track redirection

  const profileData = useSelector((state) => state.auth.getprofile);
  const { status, isTrialAvailed } = useSelector((state) => state.subscription);

  const subscriptionActive = [
    SubscriptionStatus.ACTIVE,
    SubscriptionStatus.TRIAL,
  ].includes(status);

  const createSubscription = async (userId) => {
    setRedirecting(true); // Start redirecting process

    try {
      const data = await createOrganizationSubscription({
        userId: userId,
        successUrl: window.location.origin + '/my-business',
        cancelUrl: window.location.href,
      });

      window.location.href = data.url; // Redirect after success
    } catch (err) {
      console.log(err);
      setRedirecting(false); // If an error occurs, stop showing "Redirecting..."
    }
  };

  const handleSubscriptionAction = async () => {
    const userId = profileData?.userProfile?.id;
    if (!userId || redirecting) return; // Prevent multiple clicks

    await createSubscription(userId);
  };

  const renderSubscriptionModal = () => {
    return (
      <Modal
        open={modals.subscribe}
        onClose={() => {
          if (!redirecting) {
            // Prevent closing if redirecting
            setModals((prev) => ({ ...prev, subscribe: false }));
            setShowSubscriptionModal(false);
          }
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <MDBox sx={style}>
          <MDBox sx={{ position: 'relative', pt: '15px' }}>
            <MDBox>
              <h2
                id='modal-modal-title'
                style={{
                  fontFamily: 'urbanist',
                  fontSize: '24px',
                  fontWeight: 700,
                  color: '#333',
                  textAlign: 'center',
                }}
              >
                {redirecting ? 'Redirecting...' : 'Start your free trial'}
              </h2>
              <p
                id='modal-modal-description'
                style={{
                  fontFamily: 'urbanist',
                  fontSize: '18px',
                  fontWeight: 400,
                  lineHeight: '30px',
                  marginTop: '14px',
                  color: '#818181',
                  textAlign: 'center',
                }}
              >
                {redirecting
                  ? 'Redirecting to the subscription page to activate your free trial...'
                  : isTrialAvailed
                  ? 'Subscribe now to add your business and explore our premium features.'
                  : 'Please start your 30-day free trial to add your business and get insights to grow your business.'}
              </p>
            </MDBox>

            {!redirecting && (
              <>
                <MDButton
                  variant='gradient'
                  color='error'
                  sx={{
                    paddingLeft: '4rem',
                    paddingRight: '4rem',
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                    borderRadius: '45px',
                    marginTop: '30px',
                  }}
                  onClick={() => {
                    setModals((prev) => ({ ...prev, subscribe: false }));
                    setShowSubscriptionModal(false);
                  }}
                >
                  Cancel
                </MDButton>

                <MDButton
                  variant='gradient'
                  color='info'
                  sx={{
                    paddingLeft: '4rem',
                    paddingRight: '4rem',
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                    borderRadius: '45px',
                    marginTop: '30px',
                    marginLeft: '16px',
                  }}
                  onClick={handleSubscriptionAction}
                  disabled={redirecting} // Disable button while redirecting
                >
                  {isTrialAvailed ? 'Subscribe' : 'Start Free Trial'}
                </MDButton>
              </>
            )}
          </MDBox>
        </MDBox>
      </Modal>
    );
  };

  return <>{renderSubscriptionModal()}</>;
};

export default SubscriptionModel;
