import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import { createOrganizationSubscription } from 'services/subscription';
import { SubscriptionStatus } from 'constant';
import { cancelOrganizationSubscription } from 'services/subscription';
import { restartOrganizationSubscription } from 'services/subscription';
import { Modal } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDSnackbar from 'components/MDSnackbar';
import { toast } from 'react-toastify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '24px',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
  py: 5,
  zIndex: 2000,
};
const SubscribeMenuItem = () => {
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [modals, setModals] = useState({
    cancelSubscription: false,
    confirmCancel: false,
    subscribe: false,
  });
  const profileData = useSelector((state) => state.auth.getprofile);
  const { status, billingAnchor, isTrialAvailed } = useSelector(
    (state) => state.subscription
  );
  const nextBillingDate = billingAnchor ? new Date(billingAnchor) : null;
  const subscriptionActive = [
    SubscriptionStatus.ACTIVE,
    SubscriptionStatus.TRIAL,
  ].includes(status);

  const renderMenuItemText = () => {
    if (
      [
        SubscriptionStatus.EXPIRED,
        SubscriptionStatus.CANCELED,
        SubscriptionStatus.CANCELED_AT_END,
      ].includes(status)
    ) {
      return 'Restart Sub..';
    }

    return [SubscriptionStatus.ACTIVE, SubscriptionStatus.TRIAL].includes(
      status
    )
      ? 'Unsubscribe'
      : 'Subscribe';
  };

  const createSubscription = async (userId) => {
    const data = await createOrganizationSubscription({
      userId: userId,
      successUrl: window.location.origin + '/my-business',
      cancelUrl: window.location.href,
    });

    setShowNotification(true);

    setTimeout(() => {
      setShowNotification(false);
      window.location.href = data.url;
    }, 2000);
  };

  const handleSubscriptionAction = async () => {
    const userId = profileData?.userProfile?.id;

    try {
      if (!userId) {
        return;
      }

      if (subscriptionActive) {
        await cancelOrganizationSubscription(userId);
        setModals((prev) => ({
          ...prev,
          confirmCancel: true,
        }));
        setTimeout(() => {
          setModals((prev) => ({
            ...prev,
            confirmCancel: false,
          }));
          window.location.reload();
        }, 2000);
      } else {
        await createSubscription(userId);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onClickRestartSubscriptionHandler = async () => {
    const userId = profileData?.userProfile?.id;

    try {
      if (!userId) {
        return;
      }

      await restartOrganizationSubscription(userId);
      toast.success('Subscription restarted successfully!');
      window.location.reload();
    } catch (err) {}
  };

  const handleClick = () => {
    if (
      [
        SubscriptionStatus.EXPIRED,
        SubscriptionStatus.CANCELED,
        SubscriptionStatus.CANCELED_AT_END,
      ].includes(status)
    ) {
      onClickRestartSubscriptionHandler();
    } else if (
      [SubscriptionStatus.ACTIVE, SubscriptionStatus.TRIAL].includes(status)
    ) {
      setModals((prev) => ({
        ...prev,
        cancelSubscription: true,
      }));
    } else {
      setModals((prev) => ({
        ...prev,
        subscribe: true,
      }));
    }

    return [SubscriptionStatus.ACTIVE, SubscriptionStatus.TRIAL].includes(
      status
    )
      ? 'Unsubscribe'
      : 'Subscribe';
  };

  const renderCancelSubscriptionModal = () => {
    return (
      <Modal
        open={modals.cancelSubscription}
        onClose={() =>
          setModals((prev) => ({ ...prev, cancelSubscription: false }))
        }
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        key='cancel-subscription-modal'
      >
        <MDBox sx={style}>
          <MDBox sx={{ position: 'relative', pt: '15px' }}>
            <MDBox
              sx={{
                position: 'absolute',
                top: '-15px',
                left: '38%',
              }}
            ></MDBox>
            <MDBox>
              <h2
                id='modal-modal-title'
                style={{
                  fontFamily: 'urbanist',
                  fontSize: '24px',
                  fontWeight: 700,
                  color: '#333',
                  textAlign: 'center',
                }}
              >
                Unsubscribe
              </h2>
              <p
                id='modal-modal-description'
                style={{
                  fontFamily: 'urbanist',
                  fontSize: '18px',
                  fontWeight: 400,
                  lineHeight: '30px',
                  marginTop: '14px',
                  color: '#818181',
                  textAlign: 'center',
                }}
              >
                Are you sure you want to cancel your subscription? Please
                confirm to proceed.
              </p>
            </MDBox>

            <MDButton
              variant='gradient'
              color='error'
              sx={{
                paddingLeft: '4rem',
                paddingRight: '4rem',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                borderRadius: '45px',
                marginTop: '30px',
              }}
              onClick={() =>
                setModals((prev) => ({ ...prev, cancelSubscription: false }))
              }
            >
              Cancel
            </MDButton>
            <MDButton
              variant='gradient'
              color='info'
              sx={{
                paddingLeft: '4rem',
                paddingRight: '4rem',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                borderRadius: '45px',
                marginTop: '30px',
                marginLeft: '16px',
              }}
              onClick={() => {
                handleSubscriptionAction();
                setModals((prev) => ({ ...prev, cancelSubscription: false }));
              }}
            >
              Confirm
            </MDButton>
          </MDBox>
        </MDBox>
      </Modal>
    );
  };

  const renderConfirmCancelSubscriptionModal = () => {
    return (
      <Modal
        open={modals.confirmCancel}
        onClose={() => setModals((prev) => ({ ...prev, confirmCancel: false }))}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        key='confirmed-cancel-subscription-modal'
      >
        <MDBox sx={style}>
          <MDBox sx={{ position: 'relative', pt: '15px' }}>
            <MDBox
              sx={{
                position: 'absolute',
                top: '-15px',
                left: '38%',
              }}
            ></MDBox>
            <MDBox>
              <h2
                id='modal-modal-title'
                style={{
                  fontFamily: 'urbanist',
                  fontSize: '24px',
                  fontWeight: 700,
                  color: '#333',
                  textAlign: 'center',
                }}
              >
                Unsubscription Successful!
              </h2>
              <p
                id='modal-modal-description'
                style={{
                  fontFamily: 'urbanist',
                  fontSize: '18px',
                  fontWeight: 400,
                  lineHeight: '30px',
                  marginTop: '14px',
                  color: '#818181',
                  textAlign: 'center',
                }}
              >
                Your subscription has been canceled. You will not be charged
                starting from your next billing date
                {nextBillingDate && `: ${nextBillingDate?.toDateString?.()}`}.
              </p>
            </MDBox>
          </MDBox>
        </MDBox>
      </Modal>
    );
  };

  const renderSubscriptionModal = () => {
    return (
      <Modal
        open={modals.subscribe}
        onClose={() => setModals((prev) => ({ ...prev, subscribe: false }))}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <MDBox sx={style}>
          <MDBox sx={{ position: 'relative', pt: '15px' }}>
            <MDBox
              sx={{
                position: 'absolute',
                top: '-15px',
                left: '38%',
              }}
            ></MDBox>
            <MDBox>
              <h2
                id='modal-modal-title'
                style={{
                  fontFamily: 'urbanist',
                  fontSize: '24px',
                  fontWeight: 700,
                  color: '#333',
                  textAlign: 'center',
                }}
              >
                Confirm your Subscription
              </h2>
              <p
                id='modal-modal-description'
                style={{
                  fontFamily: 'urbanist',
                  fontSize: '18px',
                  fontWeight: 400,
                  lineHeight: '30px',
                  marginTop: '14px',
                  color: '#818181',
                  textAlign: 'center',
                }}
              >
                {isTrialAvailed
                  ? 'Subscribe now to add your business and explore our premium features.'
                  : 'Please start your 30-day free trial to add your business and explore our premium features.'}
              </p>
            </MDBox>

            <MDButton
              variant='gradient'
              color='error'
              sx={{
                paddingLeft: '4rem',
                paddingRight: '4rem',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                borderRadius: '45px',
                marginTop: '30px',
              }}
              onClick={() =>
                setModals((prev) => ({ ...prev, subscribe: false }))
              }
            >
              Cancel
            </MDButton>
            <MDButton
              variant='gradient'
              color='info'
              sx={{
                paddingLeft: '4rem',
                paddingRight: '4rem',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                borderRadius: '45px',
                marginTop: '30px',
                marginLeft: '16px',
              }}
              onClick={() => {
                handleSubscriptionAction();
                setModals((prev) => ({ ...prev, subscribe: false }));
              }}
            >
              {isTrialAvailed ? 'Subscribe' : 'Start Free Trial'}
            </MDButton>
          </MDBox>
        </MDBox>
      </Modal>
    );
  };

  const renderConfirmSubscriptionNotification = () => (
    <MDSnackbar
      color='dark'
      title='Redirecting to the subscription page to activate your free trial...'
      open={showNotification}
      bgWhite
    />
  );

  return (
    <>
      {/* <MenuItem
        className="hoverChangeColor"
        onClick={handleClick}
        sx={{
          padding: "16px 14px",
          borderRadius: "0",
          "& .MuiTypography-root": {
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "19.2px",
            color: "#292929",
            fontFamily: "urbanist",
          },
          "&:hover": {
            "& .MuiTypography-root": { color: "#ffffff" },
            backgroundColor: "#318BEC",
          },
          "&:hover svg": {
            filter: "invert(1)"
          },
          "&:hover svg line, &:hover svg path": {
            // stroke: "#333",
          },
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="20" height="20" style={{ marginRight: "8px" }}>
          <rect x="10" y="10" width="80" height="80" rx="5" fill="#161616" />
          <rect x="15" y="20" width="70" height="10" fill="#FFF" />
          <rect x="15" y="40" width="70" height="10" fill="#FFF" />
          <rect x="15" y="60" width="70" height="10" fill="#FFF" />
          <line x1="25" y1="20" x2="25" y2="30" stroke="#161616" strokeWidth="2" />
          <line x1="40" y1="40" x2="40" y2="50" stroke="#161616" strokeWidth="2" />
          <line x1="55" y1="60" x2="55" y2="70" stroke="#161616" strokeWidth="2" />
          <line x1="70" y1="20" x2="70" y2="30" stroke="#161616" strokeWidth="2" />
        </svg>
        <Typography>{renderMenuItemText()}</Typography>
      </MenuItem> */}
      {renderCancelSubscriptionModal()}
      {renderConfirmCancelSubscriptionModal()}
      {renderSubscriptionModal()}
      {renderConfirmSubscriptionNotification()}
    </>
  );
};

export default SubscribeMenuItem;
