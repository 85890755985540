import { createAsyncThunk } from "@reduxjs/toolkit";
import { serviceRating } from "features/services/dashboard";
import { processInstruction } from "features/services/dashboard";
import { reviewDistribution } from "features/services/dashboard";
import { latestReviews } from "features/services/dashboard";
import { sentimentCount } from "features/services/dashboard";
import { markAsRead } from "features/services/dashboard";
import { replayReviews } from "features/services/dashboard";
import { topNegativeReview } from "features/services/dashboard";
import { deleteCaches } from "features/services/dashboard";
import { scrapeStatus } from "features/services/dashboard";
import { autoGenerateReply } from "features/services/dashboard";
import { negativeItemCount } from "features/services/dashboard";
import { topReview } from "features/services/dashboard";
import { singleReview } from "features/services/dashboard";
import { countsRatings } from "features/services/dashboard";
import { itemCount } from "features/services/dashboard";
import { allreviews } from "features/services/dashboard";
import { reviewPerformance } from "features/services/dashboard";
import { drinkRating } from "features/services/dashboard";
import { foodRating } from "features/services/dashboard";
import { averageRatings } from "features/services/dashboard";

// register
export const ratingsCountsAsync = createAsyncThunk(
  "dasboard/count-rating",
  async (userData) => {
    try {
      const response = await countsRatings(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// register
export const averageRatingAsync = createAsyncThunk(
  "dasboard/average",
  async (userData) => {
    try {
      const response = await averageRatings(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// foodRating
export const foodRatingAsync = createAsyncThunk(
  "dasboard/food",
  async (userData) => {
    try {
      const response = await foodRating(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// drinkRating
export const drinkRatingAsync = createAsyncThunk(
  "dasboard/drink",
  async (userData) => {
    try {
      const response = await drinkRating(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// serviceRating
export const serviceRatingAsync = createAsyncThunk(
  "dasboard/service",
  async (userData) => {
    try {
      const response = await serviceRating(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// sentimentCount
export const sentimentCountAsync = createAsyncThunk(
  "dasboard/sentiment",
  async (userData) => {
    try {
      const response = await sentimentCount(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// itemCount
export const itemCountAsync = createAsyncThunk(
  "dasboard/count",
  async (userData) => {
    try {
      const response = await itemCount(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const itemCountNegativeAsync = createAsyncThunk(
  "dasboard/negative",
  async (userData) => {
    try {
      const response = await negativeItemCount(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// latestReviews
export const latestReviewAsync = createAsyncThunk(
  "dasboard/latestReview",
  async (userData) => {
    try {
      const response = await latestReviews(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// allreviews
export const allReviewAsync = createAsyncThunk(
  "dasboard/allreviews",
  async (userData) => {
    try {
      const response = await allreviews(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// reviewDistribution
export const reviewDistributionAsync = createAsyncThunk(
  "dasboard/distribution",
  async (userData) => {
    try {
      const response = await reviewDistribution(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// reviewPerformance
export const reviewPerformanceAsync = createAsyncThunk(
  "dasboard/performance",
  async (userData) => {
    try {
      const response = await reviewPerformance(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// processInstruction
export const processInstructionAsync = createAsyncThunk(
  "dasboard/instruction",
  async (userData) => {
    try {
      const response = await processInstruction(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// markAsRead
export const markAsReadAsync = createAsyncThunk(
  "dasboard/read",
  async (userData) => {
    try {
      const response = await markAsRead(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const singleReviewAsync = createAsyncThunk(
  "dasboard/singleReview",
  async (userData) => {
    try {
      const response = await singleReview(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const replayReviewsAsync = createAsyncThunk(
  "dasboard/replay",
  async (userData) => {
    try {
      const response = await replayReviews(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const topReviewAsync = createAsyncThunk(
  "dasboard/top-review",
  async (userData) => {
    try {
      const response = await topReview(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const topNegativeReviewAsync = createAsyncThunk(
  "dasboard/top-review-negative",
  async (userData) => {
    try {
      const response = await topNegativeReview(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const autoGenerateReplyAsync = createAsyncThunk(
  "dasboard/ai-reply",
  async (userData) => {
    try {
      const response = await autoGenerateReply(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteCachesAsync = createAsyncThunk(
  "dasboard/delete-caches",
  async (userData) => {
    try {
      const response = await deleteCaches(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const scrapeStatusAsync = createAsyncThunk(
  "dasboard/scrape-status",
  async (userData) => {
    try {
      const response = await scrapeStatus(userData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

