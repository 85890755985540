import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardLayoutCustom2 from "examples/LayoutContainers/DashboardLayoutCustom2";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import { getMyBusinessAysnc } from "features/actions/auth";
import { getProfileAysnc } from "features/actions/auth";
import CircularProgress from "@mui/material/CircularProgress";

import { useNavigate } from "react-router-dom";
import Loader from "assets/loader/loader";
import Tooltip from "@mui/material/Tooltip";
import { Modal, Skeleton, TablePagination, Typography } from "@mui/material";
import { scrapeStatusAsync } from "features/actions/dashboard";
import { googleTokenAsync } from "features/actions/auth";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { Warning } from "@mui/icons-material";
import { resetScrapeStatus } from "features/reducers/dashboard";
import { syncingStatus } from "examples/commonFolder/commonfolder";
import viewIcon from "assets/images/icons/view-b-icon.svg";
import updateIcon from "assets/images/icons/update-b-icon.svg";
import statusIcon from "assets/images/icons/status-b-icon.svg";
import deleteIcon from "assets/images/icons/deleteIcon.svg";
import { statusColors } from "examples/commonFolder/commonfolder";
import { getSingleBusinessAysnc } from "features/actions/auth";
import facebookIcon from "../../../assets/images/icons/facebookIcon.svg";
import yelpIcon from "../../../assets/images/icons/yelpIcon.svg";
import googleIcon from "../../../assets/images/icons/googleIcon.svg";

import AuthModal from "components/authModal";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import google from "assets/images/icons/google.svg";
import Button from "@mui/material/Button";
import yelp from "assets/images/icons/yelp.svg";
import twitter from "assets/images/icons/twitter.svg";
import facebook from "assets/images/icons/facebook.svg";
// import xIcon from "assets/images/icons/xIcon.svg";
import { statusBackgroundColor } from "examples/commonFolder/commonfolder";
import { statusIcons } from "examples/commonFolder/commonfolder";
import { removeBusinessAsync } from "features/actions/auth";
import { commonIdState } from "features/reducers/dashboard";
import { resetAuthSync } from "features/reducers/auth";
import { SubscriptionPricing } from "components/Subscribe";
import { checkAllowedBusinessCreation } from "services/subscription";
import { SubscriptionStatus } from "constant";
import { updateSrappingAsync } from "features/actions/auth";
import { resetFBscrapedData } from "features/reducers/auth";
import { getFbDataAsync } from "features/actions/auth";
import SubscriptionModel from "components/Subscribe/SubscriptionModel";

function MyBusiness() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "background.paper",
    borderRadius: "12px",
    boxShadow: 24,
    p: { xs: 1, sm: 4 },
    textAlign: "center",
    py: 2,
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [facebookStatus, setFacebookStatus] = useState("");
  const [yelpStatus, setYelpStatus] = useState("");
  const [twitterStatus, setTwitterStatus] = useState("");
  const [googleStatus, setGoogleStatus] = useState("");
  const [googleToken, setGoogleToken] = useState(false);
  const [startUpdate, setStartUpdate] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [scrapeStatusData, setScrapeStatusData] = useState({});
  const [businessIdRetry, setBusinessIdRetry] = useState("");
  const [currentPlatform, setCurrentPlatform] = useState("");
  const [userBusinessId, setUserBusinessId] = useState("");
  const [businessName, setBusinessName] = useState("");

  const [loading, setLoading] = useState(false);
  const [screenLoading, setScreenLoading] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState(false);
  // const CLIENT_ID = config?.url?.CLIENT_ID;
  const [sourceAuthModal, setSourceAuthModal] = useState(false);
  const [authModal, setAuthModal] = useState(false);
  const [yelpUrl, setyelpUrl] = useState("");
  const [fbUrl, setFbUrl] = useState("");
  const [googleUrl, setGoogleUrl] = useState("");
  const [businessData, setBusinessData] = useState();
  const [xUrl, setXUrl] = useState("");
  const [businessStatus, setBusinessStatus] = useState(false);
  const [syncType, setSyncType] = useState("");

  const [permissionModal, setPermissionModal] = useState(false);
  const [googleAuth, setGoogleAuth] = useState(false);
  const [fbAuth, setFbAuth] = useState(false);
  const [showPricing, setShowPricing] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);  // State for controlling the modal
  const [isSyncEnabled, setIsSyncEnabled] = useState(false);

  const {
    getprofile,
    myBusinessList,
    getGoogleToken,
    getSingleBusiness,
    updateSrapping,
    getScrapeFbData
  } = useSelector((state) => state.auth);
  const { scrapeStatus } = useSelector((state) => state.dashboard);
  const { status: subscriptionStatus } = useSelector((state) => state.subscription);
  const [showloader, setShowloader] = useState(false);
  const [pagination, setPagination] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [userId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const handlePageChange = (event, newPage) => {
    setPagination(true);
    setPage(newPage);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPagination(true);
    setPage(0);
  };

  const getStatusDisplay = (status) => {
    return syncingStatus[status] || syncingStatus?.NOT_STARTED;
  };
  const getStatusBackgroundColor = (status) => {
    return statusBackgroundColor[status] || "#ffffff";
  };

  const getStatusColor = (status) => {
    return statusColors[status] || "#000000"; // Default color is black
  };

  const getStatusIcon = (status) => {
    const displayStatus = getStatusDisplay(status);
    return statusIcons[displayStatus];
  };

  useEffect(() => {
    const fetchBusinessData = async () => {
      if (getprofile?.userProfile) {
        setLoading(true);
        try {
          await dispatch(
            getMyBusinessAysnc({
              user_id: getprofile?.userProfile?.id,
              page_size: rowsPerPage,
              page_number: page + 1,
            })
          );
        } catch (error) {
          console.error("Error fetching business data:", error);
        } finally {
          setLoading(false);
        }
      }
      setPagination(false);
    };

    fetchBusinessData();
  }, [getprofile, pagination, rowsPerPage, page, dispatch]);

  const handleView = (id) => {
    navigate("/view-business", { state: { id } });
  };

  const handleSync = (businessId) => {
    setShowloader(true);
    dispatch(getSingleBusinessAysnc(businessId));
    dispatch(scrapeStatusAsync({ common_id: businessId }));
    setBusinessStatus(true);
    setIsLoading(true);
    setBusinessIdRetry(businessId);
  };

  const handleUpdate = (data) => {
    setSyncType("");
    const storedGoogleToken = Cookies.get("googleAccessToken");
    const storedFbToken = Cookies.get("fbAccessToken");
    if (data == "socialAuth") {
      setGoogleAuth(storedGoogleToken ? true : false);
      setFbAuth(storedFbToken ? true : false);
      setPermissionModal(true);
      setAuthModal(true);
    } else if (data !== "socialAuth" && (storedGoogleToken || storedFbToken)) {
      setAuthModal(false);
      setBusinessData(data);
      setPermissionModal(true);
    } else if (data !== "socialAuth" && (!storedGoogleToken && !storedFbToken)) {
      setAuthModal(false);
      setPermissionModal(false);
      toast.error("Authenticate yourself.");
    }
  };

  const handleRemove = (data) => {
    setAuthModal(false);
    setSyncType("");
    if (data?.onboarding_status == "false") {
      toast.error("Onboarding process is still in progress.");
    } else {
      setPermissionModal(true);
      setBusinessName(data?.restaurant_name);
      setSyncType("removeBusiness");
      setUserBusinessId(data?.id);
    }
  };

  const handleClose = () => {
    setBusinessStatus(false);
    dispatch(resetScrapeStatus());
    setErrorStatus("");
    setScrapeStatusData({});
  };

  useEffect(() => {
    if (scrapeStatus) {
      setScrapeStatusData(scrapeStatus);
    }
  }, [scrapeStatus]);

  useEffect(() => {
    setShowloader(false);
    if (
      Object.keys(scrapeStatusData)?.length > 0 &&
      scrapeStatusData?.status_code == 200
    ) {
      setFacebookStatus(scrapeStatusData?.data?.facebook_status);
      setYelpStatus(scrapeStatusData?.data?.yelp_status);
      setTwitterStatus(scrapeStatusData?.data?.twitter_status);
      setGoogleStatus(scrapeStatusData?.data?.google_status);
    } else if (scrapeStatusData?.status_code == 400) {
      setErrorStatus(scrapeStatusData?.message);
    }
  }, [scrapeStatusData]);

  useEffect(() => {
    if (getSingleBusiness) {
      setyelpUrl(getSingleBusiness?.business_yelp_page);
      setFbUrl(getSingleBusiness?.business_fb_page);
      setGoogleUrl(getSingleBusiness?.business_google_page);
      setXUrl(getSingleBusiness?.business_twitter_page);
    }
  }, [getSingleBusiness]);

  // useEffect(() => {
  //   const token = Cookies.get("accessToken");
  //   setGoogleToken(token);
  // }, []);

  useEffect(() => {
    const storedGoogleToken = Cookies.get("googleAccessToken");
    const storedFbToken = Cookies.get("fbAccessToken");

    setAuthModal(false);
    if (startUpdate) {
      if (syncType === "") {
        setLoading(true);
        if (storedGoogleToken && storedFbToken) {
          dispatch(
            googleTokenAsync({
              access_token: storedGoogleToken,
              common_id: businessData?.id,
            })
          );
          dispatch(
            getFbDataAsync({
              access_token: storedFbToken,
              common_id: businessData?.id,
            })
          );
        }
        // If only the Google token is available
        else if (storedGoogleToken && !storedFbToken) {
          dispatch(
            googleTokenAsync({
              access_token: storedGoogleToken,
              common_id: businessData?.id,
            })
          );
        }
        // If only the Facebook token is available
        else if (storedFbToken && !storedGoogleToken) {
          dispatch(
            getFbDataAsync({
              access_token: storedFbToken,
              common_id: businessData?.id,
            })
          );
        }
        else {
          toast.error("Please Authenticate");
        }
      } else if (syncType === "removeBusiness") {
        removeBusiness();
      }
      // Reset states after processing
      setSyncType("");
      setStartUpdate(false);
      setPermissionModal(false);
    }
  }, [startUpdate, syncType, dispatch]);


  useEffect(() => {
    const handleSyncResponse = (data, message, resetAction) => {
      if (data && typeof data?.status_code !== "undefined") {
        if (data.status_code === 202) {
          toast.success(message);
          dispatch(resetAction());
        } else {
          const shortMessage = message.split(" ").slice(0, 3).join(" ");
          toast.error(`An error occurred while initiating ${shortMessage?.toLowerCase()}.`);
        }
        setLoading(false);
      }
    };
    handleSyncResponse(
      getGoogleToken,
      "Google review syncing initiated.",
      resetAuthSync
    );
    handleSyncResponse(
      getScrapeFbData,
      "Facebook review syncing initiated.",
      resetFBscrapedData
    );
  }, [getGoogleToken, getScrapeFbData, dispatch]);


  const handleIconClick = (platform) => {
    setAuthModal(false);
    setPermissionModal(false);
    if (platform === "Google" || platform === "Facebook") {
      setCurrentPlatform(platform);
      setSourceAuthModal(true);
    } else {
      toast.custom((t) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "16px",
            borderRadius: "8px",
            width: "360px",
            background: t.visible ? "white" : "transparent",
            boxShadow: "0px 6px 9px rgba(194, 191, 193, 0.24)",
          }}
        >
          <Warning style={{ color: "yellow", marginRight: "8px" }} />
          <span
            style={{
              marginBottom: "0px",
              lineHeight: "1.2",
            }}
          >
            Authentication feature for {platform} is coming soon.
          </span>{" "}
        </div>
      ));
    }
  };

  const removeBusiness = async () => {
    setScreenLoading(true);
    try {
      await dispatch(
        removeBusinessAsync({
          user_id: getprofile?.userProfile?.id,
          restaurant_id: userBusinessId,
        })
      );
      await dispatch(getProfileAysnc());
      await dispatch(
        getMyBusinessAysnc({
          user_id: getprofile?.userProfile?.id,
          page_size: rowsPerPage,
          page_number: page + 1,
        })
      );
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setSelectedBusiness(true);
      setScreenLoading(false);
      setStartUpdate(false);
      setBusinessName("");
    }
  };

  useEffect(() => {
    if (selectedBusiness) {
      const businessId = localStorage.getItem("common_id");
      if (userBusinessId == businessId) {
        const matchingBusinesses = getprofile?.businesses?.filter(
          (ele) => ele?.onboarding_status == "true"
        );
        if (matchingBusinesses?.length > 0) {
          matchingBusinesses.forEach((ele) => {
            localStorage.setItem("common_id", ele.common_id);
            dispatch(commonIdState(ele.common_id));
          });
        } else {
          localStorage.setItem("common_id", "0");
          dispatch(commonIdState("0"));
        }
        setSelectedBusiness(false);
        setUserBusinessId("");
      } else {
        setUserBusinessId("");
        setSelectedBusiness(false);
      }
    }
  }, [selectedBusiness, userBusinessId, getprofile, dispatch]);

  const addBusinessHandler = async () => {
    const userId = getprofile?.userProfile?.id;

    if (![SubscriptionStatus.ACTIVE, SubscriptionStatus.TRIAL, SubscriptionStatus.CANCELED_AT_END].includes(subscriptionStatus)) {
      console.log(subscriptionStatus)
      setShowSubscriptionModal(true);
      return;
    }

    if (!userId) {
      return;
    }

    try {
      const allowBusinessCreationResponse = await checkAllowedBusinessCreation(userId);

      if (allowBusinessCreationResponse.allow_entry) {
        return navigate('/business-detail')
      }

      setShowPricing(true);

    } catch (err) { }
  }

  useEffect(() => {
    if (scrapeStatus?.data) {
      const isCompleted = Object.values(scrapeStatus.data).includes("COMPLETED");
      setIsSyncEnabled(isCompleted);
    }
  }, [scrapeStatus]);

  const handleSubmit = () => {
    const formDataObject = {
      id: getprofile?.userProfile?.id,
      common_id: businessIdRetry,
      rest_address: getSingleBusiness?.business_address,
      email: getprofile?.userProfile?.email,
      first_name: getprofile?.userProfile?.first_name,
      address: `${getSingleBusiness?.restaurant_name?.toLowerCase()}, ${getSingleBusiness?.business_address?.toLowerCase()}`,
      title_name: getSingleBusiness?.restaurant_name
        ?.replace(/\s+/g, "")
        ?.toLowerCase(),
    };

    if (
      getSingleBusiness?.business_yelp_page &&
      getSingleBusiness?.business_yelp_page !== "N/A"
    ) {
      formDataObject.yelp_url = getSingleBusiness?.business_yelp_page;
    }
    if (
      getSingleBusiness?.business_twitter_page &&
      getSingleBusiness?.business_twitter_page !== "N/A"
    ) {
      formDataObject.twitter_url = getSingleBusiness?.business_twitter_page;
    }
    if (
      getSingleBusiness?.business_fb_page &&
      getSingleBusiness?.business_fb_page !== "N/A"
    ) {
      formDataObject.facebook_url = getSingleBusiness?.business_fb_page;
    }
    if (
      getSingleBusiness?.business_google_page &&
      getSingleBusiness?.business_google_page !== "N/A"
    ) {
      formDataObject.google_url = getSingleBusiness?.business_google_page;
    }
    dispatch(updateSrappingAsync(formDataObject));
    toast.success("Syncing has been started");
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // Stop loading after 5–6 seconds
    }, 3000);
    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, [isLoading]);

  useEffect(() => {
    if (updateSrapping?.status_code == 202) {
      handleClose();
    }
  }, [updateSrapping]);
  return (
    <>
      <MDBox sx={{ backgroundColor: "#f9f9f9", minHeight: "100vh" }}>
        <DashboardLayoutCustom2>
          {screenLoading && <Loader />}
          <DashboardNavbar />
          <MDBox mb={4} />

          <MDBox
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: { xs: "16px", sm: "45px" },
              p: { xs: "8px", sm: 2 },
              "@media(max-width:786px)": { mx: 2 },
            }}
          >
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                "@media(min-width:576px)": { flexDirection: "row" },
              }}
            >
              <MDTypography
                mt={2}
                mb={2}
                sx={{
                  color: "#292929",
                  fontFamily: "Urbanist",
                  fontSize: "24px",
                  fontWeight: 700,
                  lineHeight: "28.8px",
                }}
              >
                My Businesses
              </MDTypography>
              <MDBox
                sx={{
                  textAlign: "right",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: { xs: "10px", lg: "25px" },
                }}
              >
                {/* <MDBox style={{ display: "flex", justifyContent: "flex-end" }}>
                  {getprofile?.businesses?.length !== 0 &&
                    getprofile?.user_business_status?.[0]?.true_count !== 0 && (
                      <MDTypography
                        mt={{ xs: 0, sm: 2 }}
                        mb={{ xs: 0, sm: 2 }}
                        sx={{ color: "#344767" }}
                      >
                        <MDButton
                          elevation={0}
                          onClick={() => handleUpdate("socialAuth")}
                          sx={{
                            fontSize: "15px",
                            cursor: "pointer",
                            borderRadius: "45px",
                            backgroundColor: "#318BEC",
                            fontFamily: "urbanist",
                            textTransform: "capitalize",
                            mr: 1,
                          }}
                          color="info"
                        >
                          Authenticate
                        </MDButton>
                      </MDTypography>
                    )}
                  {sourceAuthModal && (
                    <AuthModal
                      sourceAuthModal={sourceAuthModal}
                      setSourceAuthModal={setSourceAuthModal}
                      setSourceModal={() => setSourceAuthModal(false)}
                      platform={currentPlatform}
                    />
                  )}
                </MDBox> */}
                <MDTypography
                  mt={{ xs: 0, sm: 2 }}
                  mb={{ xs: 0, sm: 2 }}
                  sx={{ color: "#344767" }}
                >
                  <MDButton
                    elevation={0}
                    sx={{
                      cursor: "pointer",
                      borderRadius: "45px",
                      backgroundColor: "#318BEC",
                      fontFamily: "urbanist",
                      textTransform: "capitalize",
                      mr: 1,
                      fontSize: "15px",
                    }}
                    color="info"
                    onClick={addBusinessHandler}
                  >
                    Add Business
                  </MDButton>
                </MDTypography>
              </MDBox>
            </MDBox>

            <MDBox
              sx={{
                width: "100%",
                "@media(max-width:900px)": { overflowX: "auto" },
              }}
            >
              <Table
                sx={{
                  overflowX: "scroll",
                  "@media(max-width:900px)": { maxWidth: "750px" },
                }}
                aria-label="simple table"
              >
                <TableHead
                  sx={{
                    borderBottom: "1px solid #F4F4F4",
                    width: "100%",
                    display: "table-header-group",
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "19px",
                        color: "#707EAE",
                        textAlign: "left",
                        width: "10%",
                        fontFamily: "Urbanist",
                        pt: 3,
                        px: 3,
                        pb: 3,
                      }}
                    >
                      S.No.
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "19px",
                        color: "#707EAE",
                        textAlign: "left",
                        width: "20%",
                        fontFamily: "Urbanist",
                        pt: 3,
                        px: 3,
                        pb: 3,
                      }}
                    >
                      Business Name
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "19px",
                        color: "#707EAE",
                        textAlign: "left",
                        width: "50%",
                        fontFamily: "Urbanist",
                        pt: 3,
                        px: 3,
                        pb: 3,
                      }}
                    >
                      Business Address
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "19px",
                        color: "#707EAE",
                        width: "20%",
                        fontFamily: "Urbanist",
                        pt: 3,
                        px: 3,
                        pb: 3,
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {myBusinessList?.data?.length > 0 ? (
                    myBusinessList?.data?.map((ele, index) => {
                      const serialNumber = index + 1;
                      return (
                        <TableRow key={index}>
                          <TableCell
                            align="left"
                            sx={{
                              px: 3,
                              textTransform: "capitalize",
                              fontFamily: "Urbanist",
                              fontSize: "14px",
                              fontWeight: 600,
                              lineHeight: "16.8px",
                              width: "10%",
                              textAlign: "left",
                            }}
                          >
                            {loading ? (
                              <Skeleton
                                variant="rectangular"
                                style={{
                                  marginTop: "5px",
                                  borderRadius: "5px",
                                }}
                                width={40}
                                height={37}
                              />
                            ) : (
                              serialNumber
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              px: 3,
                              fontFamily: "Urbanist",
                              fontSize: "14px",
                              fontWeight: 600,
                              lineHeight: "16.8px",
                              width: "40%",
                              textAlign: "left",
                            }}
                            align="left"
                          >
                            {loading ? (
                              <Skeleton
                                variant="rectangular"
                                style={{
                                  marginTop: "5px",
                                  borderRadius: "5px",
                                }}
                                width={400}
                                height={37}
                              />
                            ) : ele?.restaurant_name.includes("sample") ? (
                              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <span>{ele?.restaurant_name} </span>
                                <Tooltip title="Example business for demonstration purposes. Feel free to explore!"
                                  placement="bottom">
                                  <InfoOutlinedIcon
                                    sx={{
                                      fontSize: "18px", // Adjust size as needed
                                      color: "#1976d2", // Optional: Customize the icon color
                                      cursor: "pointer",
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            ) : (
                              ele?.restaurant_name
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              px: 3,
                              fontFamily: "Urbanist",
                              fontSize: "14px",
                              fontWeight: 600,
                              lineHeight: "16.8px",
                              width: "40%",
                              textAlign: "left",
                            }}
                            align="left"
                          >
                            {loading ? (
                              <Skeleton
                                variant="rectangular"
                                style={{
                                  marginTop: "5px",
                                  borderRadius: "5px",
                                }}
                                width={600}
                                height={37}
                              />
                            ) : (
                              ele?.business_address
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              pl: 3,
                              pr: 2,
                              fontSize: "14px",
                              fontWeight: 600,
                              lineHeight: "16.8px",
                              width: "10%",
                              textAlign: "left",
                            }}
                            align="right"
                          >
                            {loading ? (
                              <Skeleton
                                variant="rectangular"
                                style={{
                                  marginTop: "5px",
                                  borderRadius: "5px",
                                }}
                                width={150}
                                height={37}
                              />
                            ) : (
                              <MDBox
                                sx={{
                                  display: "flex",
                                  alignItems: "right",
                                  justifyContent: "right",
                                  gap: "15px",
                                }}
                              >
                                <Tooltip title="View" placement="top">
                                  <a
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleView(ele.id)}
                                  >
                                    <img src={viewIcon} alt="profile_image" />
                                  </a>
                                </Tooltip>
                                <Tooltip title="Sync status" placement="top">
                                  <a
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleSync(ele?.id)}
                                  >
                                    <img src={statusIcon} alt="profile_image" />
                                  </a>
                                </Tooltip>
                                {/* <Tooltip title="Update" placement="top">
                                  <a
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleUpdate(ele)}
                                  >
                                    <img src={updateIcon} alt="profile_image" />
                                  </a>
                                </Tooltip> */}
                                <Tooltip title="Delete" placement="top">
                                  <a
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleRemove(ele)}
                                  >
                                    <img src={deleteIcon} alt="profile_image" />
                                  </a>
                                </Tooltip>
                              </MDBox>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <>
                      <tr>
                        <td colSpan="6">
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "16px",
                              fontWeight: "500",
                              lineHeight: "10px",
                              color: "#707EAE",
                              padding: "10px",
                            }}
                          >
                            No data found
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                  <TableRow style={{ height: 53 }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                </TableBody>
              </Table>
              <TablePagination
                sx={{
                  fontFamily: "urbanist",
                  fontWeight: "700",
                  "& .css-p33a0u-MuiTablePagination-selectLabel": {
                    fontFamily: "urbanist",
                    fontWeight: "500",
                  },
                  "& .css-r9d6bn-MuiSelect-select-MuiInputBase-input.css-r9d6bn-MuiSelect-select-MuiInputBase-input.css-r9d6bn-MuiSelect-select-MuiInputBase-input":
                    { fontFamily: "urbanist", fontWeight: "600" },
                  "& .css-188flkz-MuiTablePagination-displayedRows ": {
                    fontFamily: "urbanist",
                    fontWeight: "500",
                  },
                }}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={myBusinessList?.total_businesses || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </MDBox>
          </MDBox>
        </DashboardLayoutCustom2>
      </MDBox>

      <Modal
        open={businessStatus}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox sx={style}>
          {showloader ? (
            <MDBox sx={{ position: "relative", pt: 4 }}>
              <MDBox
                sx={{
                  position: "absolute",
                  top: "105px",
                  left: "35%",
                }}
              ></MDBox>
              <Typography
                id="modal-modal-title"
                sx={{
                  fontFamily: "urbanist",
                  fontSize: "24px",
                  fontWeight: 700,
                  lineHeight: "40.32px",
                  color: "#353535",
                  px: 4,
                }}
              >
                <CircularProgress color="inherit" />
                <br />
                Please wait
                <br />
              </Typography>
            </MDBox>
          ) : errorStatus ? (
            <MDBox>
              <p
                style={{
                  color: "#318BEC",
                  fontWeight: "700",
                  textTransform: "capitalize",
                }}
              >
                {errorStatus}
              </p>
              <MDButton
                color="info"
                variant="contained"
                sx={{ borderRadius: "45px", my: 2 }}
                onClick={() => handleSync(businessIdRetry)}
              >
                Try Again
              </MDButton>
            </MDBox>
          ) : (
            <>
              <div style={{ position: "relative" }}>
                <p
                  style={{
                    fontFamily: "urbanist",
                    fontWeight: "700",
                    fontSize: "22px",
                    color: "#323232",
                    lineHeight: "22px",
                    textTransform: "capitalize",
                  }}
                >
                  Business Syncing status with portal
                </p>
                <MDBox
                  sx={{
                    px: 2,
                    pt: 2,
                    borderRadius: "24px",
                  }}
                >
                  {googleUrl && googleUrl != "N/A" && (
                    <MDBox
                      sx={{
                        display: "flex",

                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: "1px solid #DBDBDB",
                        py: 3,
                        px: 1,
                      }}
                    >
                      {isLoading ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Skeleton variant="circular" width={40} height={40} />
                          <p
                            style={{
                              fontWeight: "500",
                              fontSize: "14px",
                            }}
                          >
                            Fetching Google data...
                          </p>
                        </div>
                      ) : (
                        <>
                          <img src={google} alt="status icon" />
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              background: `${getStatusBackgroundColor(
                                googleStatus
                              )}`,
                              borderRadius: "80px",
                              padding: "9px 20px 9px 20px",
                              border: `1px solid ${getStatusColor(
                                googleStatus
                              )}`,
                              minWidth: "138px",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={getStatusIcon(googleStatus)}
                              alt="status icon"
                            />

                            <p
                              style={{
                                color: getStatusColor(googleStatus),
                                fontWeight: "500",
                                fontSize: "14px",
                              }}
                            >
                              {getStatusDisplay(googleStatus)}
                            </p>
                          </div>
                        </>
                      )}
                    </MDBox>
                  )}

                  {yelpUrl && yelpUrl != "N/A" && (
                    <MDBox
                      sx={{
                        display: "flex",

                        justifyContent: "space-between",
                        alignItems: "center",
                        borderBottom: "1px solid #DBDBDB",
                        py: 3,
                        px: 1,
                      }}
                    >
                      {isLoading ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Skeleton variant="circular" width={40} height={40} />
                          <p
                            style={{
                              fontWeight: "500",
                              fontSize: "14px",
                            }}
                          >
                            Fetching Yelp data...
                          </p>
                        </div>
                      ) : (
                        <>
                          <img src={yelp} alt="status icon" />

                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              background: `${getStatusBackgroundColor(
                                yelpStatus
                              )}`,
                              borderRadius: "80px",
                              padding: "9px 20px 9px 20px",
                              border: `1px solid ${getStatusColor(yelpStatus)}`,
                              minWidth: "138px",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={getStatusIcon(yelpStatus)}
                              alt="status icon"
                            />
                            <p
                              style={{
                                color: getStatusColor(yelpStatus),
                                fontWeight: "500",
                                fontSize: "14px",
                              }}
                            >
                              {getStatusDisplay(yelpStatus)}
                            </p>
                          </div>
                        </>
                      )}
                    </MDBox>
                  )}
                  {fbUrl && fbUrl != "N/A" && (
                    <MDBox
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid #DBDBDB",
                        alignItems: "center",
                        justifyContent: "space-between",
                        py: 3,
                        px: 1,
                      }}
                    >
                      {isLoading ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Skeleton variant="circular" width={40} height={40} />
                          <p
                            style={{
                              fontWeight: "500",
                              fontSize: "14px",
                            }}
                          >
                            Fetching Facebook data...
                          </p>
                        </div>
                      ) : (
                        <>
                          <img src={facebook} alt="Facebook icon" />

                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              background: `${getStatusBackgroundColor(
                                facebookStatus
                              )}`,
                              borderRadius: "80px",
                              padding: "9px 20px",
                              border: `1px solid ${getStatusColor(
                                facebookStatus
                              )}`,
                              minWidth: "138px",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={getStatusIcon(facebookStatus)}
                              alt="status icon"
                            />
                            <p
                              style={{
                                color: getStatusColor(facebookStatus),
                                fontWeight: "500",
                                fontSize: "14px",
                              }}
                            >
                              {getStatusDisplay(facebookStatus)}
                            </p>
                          </div>
                        </>
                      )}
                    </MDBox>
                  )}
                  {xUrl && xUrl != "N/A" && (
                    <MDBox
                      sx={{
                        display: "flex",

                        justifyContent: "space-between",
                        alignItems: "center",
                        pt: 3,
                        px: 1,
                      }}
                    >
                      {isLoading ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Skeleton variant="circular" width={40} height={40} />
                          <p
                            style={{
                              fontWeight: "500",
                              fontSize: "14px",
                            }}
                          >
                            Fetching Twitter data...
                          </p>
                        </div>
                      ) : (
                        <>
                          <img src={twitter} alt="status icon" />

                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              background: `${getStatusBackgroundColor(
                                twitterStatus
                              )}`,
                              borderRadius: "80px",
                              padding: "9px 20px 9px 20px",
                              border: `1px solid ${getStatusColor(
                                twitterStatus
                              )}`,
                              minWidth: "138px",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={getStatusIcon(twitterStatus)}
                              alt="status icon"
                            />
                            <p
                              style={{
                                color: getStatusColor(twitterStatus),
                                fontWeight: "500",
                                fontSize: "14px",
                              }}
                            >
                              {getStatusDisplay(twitterStatus)}
                            </p>
                          </div>
                        </>
                      )}
                    </MDBox>
                  )}

                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginTop: "40px",
                      minWidth: "138px",
                      justifyContent: "right",
                    }}
                  >
                    {isSyncEnabled && (
                    <MDButton
                      elevation={0}
                      sx={{
                        cursor: "pointer",
                        borderRadius: "45px",
                        backgroundColor: "#318BEC",
                        fontFamily: "urbanist",
                        textTransform: "capitalize",
                        mr: 1,
                        fontSize: "15px",
                      }}
                      disabled={isLoading}
                      color="info"
                      onClick={handleSubmit}
                    >
                      Sync Now
                    </MDButton>
                    )}
                  </div>
                </MDBox>
              </div>

              <MDTypography
                mt={2}
                mb={2}
                sx={{ color: "#344767" }}
              ></MDTypography>
            </>
          )}
        </MDBox>
      </Modal>

      <Modal
        open={permissionModal}
        onClose={() => {
          setPermissionModal(false);
          setAuthModal(false);
          setUserBusinessId("");
          setBusinessName("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {authModal ? (
          <MDBox sx={style}>
            <MDBox
              sx={{ my: 2, width: "100%", mx: "auto", textAlign: "center" }}
            >
              <p
                style={{
                  fontFamily: "urbanist",
                  fontWeight: "700",
                  fontSize: "22px",
                  color: "#323232",
                  lineHeight: "22px",
                  textTransform: "capitalize",
                  marginBottom: "24px",
                }}
              >
                Authenticate with Social Account
              </p>
              <Button
                style={{
                  textAlign: "left",
                  fontWeight: 700,
                  fontSize: "20px",
                  backgroundColor: fbAuth ? "rgb(190 233 200)" : "#fff",
                  boxShadow: "0px 6px 9px rgba(0, 0, 0, 0.2)",
                  borderRadius: "45px",
                  marginBottom: "10px",
                  color: "#a8aaad",
                  width: "auto",
                  padding: "15px",
                  "&:onhover": {
                    boxShadow: "0px 8px 11px rgba(0, 0, 0, 0.4)",
                    backgroundColor: "#2e70db",
                  },
                }}
                onClick={() => handleIconClick("Facebook")}
                disabled={fbAuth ? true : false}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                    fontSize: "16px",
                  }}
                >
                  <img
                    src={facebookIcon}
                    alt="icon"
                    style={{ marginRight: "15px" }}
                  />
                  {fbAuth ? "Authenticated" : "Authenticate with Facebook"}
                </div>
              </Button>
            </MDBox>

            <MDBox
              sx={{ my: 2, width: "100%", mx: "auto", textAlign: "center" }}
            >
              <Button
                style={{
                  textAlign: "left",
                  fontWeight: 700,
                  fontSize: "20px",
                  backgroundColor: googleAuth ? "rgb(190 233 200)" : "#fff",
                  boxShadow: "0px 6px 9px rgba(0, 0, 0, 0.2)",
                  borderRadius: "45px",
                  marginBottom: "10px",
                  color: "#a8aaad",
                  width: "auto",
                  padding: "15px 20px",
                  "&:hover": {
                    boxShadow: "0px 8px 11px rgba(0, 0, 0, 0.4)",
                    backgroundColor: "#2e70db",
                  },
                }}
                onClick={() => {
                  if (!googleAuth) {
                    handleIconClick("Google");
                  }
                }}
                disabled={googleAuth ? true : false} // Disable button if authenticated
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                    fontSize: "16px",
                  }}
                >
                  <img
                    src={googleIcon}
                    alt="icon"
                    style={{ marginRight: "15px" }}
                  />
                  {googleAuth ? "Authenticated" : "Authenticate with Google"}
                </div>
              </Button>
            </MDBox>

            <MDBox
              sx={{ my: 2, width: "100%", mx: "auto", textAlign: "center" }}
            >
              <Button
                style={{
                  textAlign: "left",
                  fontWeight: 700,
                  fontSize: "20px",
                  backgroundColor: "#fff",
                  boxShadow: "0px 6px 9px rgba(0, 0, 0, 0.2)",
                  borderRadius: "45px",
                  marginBottom: "10px",
                  color: "#a8aaad",
                  width: "auto",
                  padding: "15px 20px",
                  "&:hover": {
                    boxShadow: "0px 8px 11px rgba(0, 0, 0, 0.4)",
                    backgroundColor: "#2e70db",
                  },
                }}
                onClick={() => handleIconClick("Yelp")}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                    fontSize: "16px",
                  }}
                >
                  <img
                    src={yelpIcon}
                    alt="icon"
                    style={{ marginRight: "15px" }}
                  />
                  Authenticate with Yelp
                </div>
              </Button>
            </MDBox>

            {/* <MDBox
              sx={{ my: 2, width: "100%", mx: "auto", textAlign: "center" }}
            >
              <Button
                style={{
                  textAlign: "left",
                  fontWeight: 700,
                  fontSize: "20px",
                  backgroundColor: "#fff",
                  boxShadow: "0px 6px 9px rgba(0, 0, 0, 0.2)",
                  borderRadius: "45px",
                  marginBottom: "10px",
                  color: "#a8aaad",
                  width: "auto",
                  padding: "15px 20px",
                  "&:hover": {
                    boxShadow: "0px 8px 11px rgba(0, 0, 0, 0.4)",
                    backgroundColor: "#2e70db",
                  },
                }}
                onClick={() => handleIconClick("X")}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                    fontSize: "16px",
                  }}
                >
                  <img src={xIcon} alt="icon" style={{ marginRight: "15px" }} />
                  Authenticate with X
                </div>
              </Button>
            </MDBox> */}
          </MDBox>
        ) : (
          <MDBox sx={style}>
            <MDBox sx={{ position: "relative", pt: "15px" }}>
              <MDBox
                sx={{
                  position: "absolute",
                  top: "-15px",
                  left: "38%",
                }}
              ></MDBox>
              <MDBox>
                <h2
                  id="modal-modal-title"
                  style={{
                    fontFamily: "urbanist",
                    fontSize: "24px",
                    fontWeight: 700,
                    color: "#333",
                    textAlign: "center",
                  }}
                >
                  {syncType === "removeBusiness"
                    ? `Confirm Deletion?`
                    : "Confirm Sync?"}
                </h2>
                <p
                  id="modal-modal-description"
                  style={{
                    fontFamily: "urbanist",
                    fontSize: "18px",
                    fontWeight: 400,
                    lineHeight: "30px",
                    marginTop: "14px",
                    color: "#818181",
                    textAlign: "center",
                  }}
                >
                  {syncType === "removeBusiness"
                    ? `Are you sure you want to delete your ${businessName} business? This action is permanent and cannot be undone.`
                    : "Are you sure you want to sync the latest reviews? This action will update your reviews to the most recent ones available."}
                </p>
              </MDBox>
              <MDButton
                variant="gradient"
                color="info"
                sx={{
                  paddingLeft: "4rem",
                  paddingRight: "4rem",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  borderRadius: "45px",
                  marginTop: "30px",
                }}
                onClick={() => {
                  setPermissionModal(false);
                  setAuthModal(false);
                  setUserBusinessId("");
                  setBusinessName("");
                }}
              >
                Cancel
              </MDButton>
              <MDButton
                variant="gradient"
                color="error"
                sx={{
                  paddingLeft: "4rem",
                  paddingRight: "4rem",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  borderRadius: "45px",
                  marginTop: "30px",
                  marginLeft: { xs: "7px", sm: "16px" },
                }}
                onClick={() => setStartUpdate(true)}
              >
                {syncType === "removeBusiness" ? "Delete" : "Sync"}
              </MDButton>
            </MDBox>
          </MDBox>
        )}
      </Modal>
      <SubscriptionPricing
        visible={showPricing}
        setVisibility={setShowPricing}
        onPriceSelectionCompleted={() => navigate('/business-detail')}
      />
            {showSubscriptionModal && <SubscriptionModel setShowSubscriptionModal={setShowSubscriptionModal} />}

    </>
  );
}

export default MyBusiness;
