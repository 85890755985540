import React, { forwardRef, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { createOrganizationSubscription } from 'services/subscription';
import { SubscriptionStatus } from 'constant';
import { cancelOrganizationSubscription } from 'services/subscription';
import { restartOrganizationSubscription } from 'services/subscription';
import { toast } from 'react-toastify';

const SubscribeButton = forwardRef(
  ({ buttonContainerStyles = {}, buttonStyles = {} }, ref) => {
    const [subscriptionActive, setIsSubscriptionActive] = useState(false);
    const status = useSelector((state) => state.subscription.status);
    const profileData = useSelector((state) => state.auth.getprofile);

    useEffect(() => {
      setIsSubscriptionActive(
        status === SubscriptionStatus.ACTIVE ||
          status === SubscriptionStatus.TRIAL
      );
    }, [status]);

    const createSubscription = async (userId) => {
      const data = await createOrganizationSubscription({
        userId: userId,
        successUrl: window.location.origin + '/my-business',
        cancelUrl: window.location.href,
      });

      window.location.href = data.url;
    };

    const onClickSubscriptionHandler = async () => {
      const userId = profileData?.userProfile?.id;

      try {
        if (!userId) {
          return;
        }

        if (subscriptionActive) {
          const cancleSubscriptionResponse =
            await cancelOrganizationSubscription(userId);
          toast.success(cancleSubscriptionResponse.msg);
          return window.location.reload();
        }

        await createSubscription(userId);
      } catch (err) {
        console.log(err);
      }
    };

    const onClickRestartSubscriptionHandler = async () => {
      const userId = profileData?.userProfile?.id;

      try {
        if (!userId) {
          return;
        }

        await restartOrganizationSubscription(userId);
        toast.success('Subscription restarted successfully!');
        window.location.reload();
      } catch (err) {}
    };

    const renderButton = () => {
      if (
        [
          SubscriptionStatus.EXPIRED,
          SubscriptionStatus.CANCELED,
          SubscriptionStatus.CANCELED_AT_END,
        ].includes(status)
      ) {
        return (
          <Button
            variant='contained'
            color='primary'
            size='medium'
            sx={{ color: '#FFFFFF', width: '100%', borderRadius: 1000 }}
            onClick={onClickRestartSubscriptionHandler}
            ref={ref}
          >
            Restart Subscription
          </Button>
        );
      }

      return (
        <Button
          variant='contained'
          color='primary'
          size='medium'
          sx={{
            color: '#FFFFFF',
            width: '100%',
            borderRadius: 1000,
            ...buttonStyles,
          }}
          onClick={onClickSubscriptionHandler}
          ref={ref}
        >
          {subscriptionActive ? 'Unsubscribe' : 'Subscribe'}
        </Button>
      );
    };

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginInline: '1rem',
          ...buttonContainerStyles,
        }}
      >
        {renderButton()}
      </div>
    );
  }
);

export default SubscribeButton;
