import React, { useEffect, useState, useRef } from "react";
import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import { config } from "constant";
import { gapi } from "gapi-script";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import googleIcon from "../../assets/images/icons/googleIcon.svg";
import facebookIcon from "../../assets/images/icons/facebook-icon.svg";
import toast from "react-hot-toast";
import TwitterLogin from "react-twitter-auth";
import modallogo from "assets/images/icons/modal-logo.svg";
import modalcross from "assets/images/icons/modal-cross.svg";
import { facebookTokenAsync } from "features/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { clearFbTokenState } from "features/reducers/auth";

const AuthModal = ({
  sourceAuthModal,
  setSourceAuthModal,
  setSourceModal,
  platform,
}) => {
  const CLIENT_ID = config?.url?.CLIENT_ID;
  const fbRef = useRef(null);
  const dispatch = useDispatch()
  const [facebookToken, setFacebookToken] = useState(false);
  const [working, setWorking] = useState(false)
  const { fbToken } = useSelector((state) => state.auth)

  // Google authentication func

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: CLIENT_ID,
        scope: "https://www.googleapis.com/auth/userinfo.profile", // Specify the scopes here
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);

  const handleAuthClick = () => {
    gapi.auth2
      .getAuthInstance()
      .signIn()
      .then((response) => {
        const authResponse = response.getAuthResponse();
        if (authResponse?.access_token && authResponse.expires_in) {
          const expirationDate = new Date(
            new Date().getTime() + authResponse.expires_in * 1000
          );
          Cookies.set("googleAccessToken", authResponse?.access_token, {
            expires: expirationDate,
          });
          toast.success(`Authenticated successfully with ${platform}`);
          setSourceAuthModal(false);
          setSourceModal(false);
        }
      })
      .catch((error) => {
        console.error("Google Login Failed:", error);
      });
  };


  // facebook authentication func

  const handleFacebookClick = () => {
    const url = 'https://www.facebook.com/v12.0/dialog/oauth?client_id=4475162612701279&redirect_uri=https://staging.reviewattendant.com/&scope=pages_show_list';
    const newWindow = window.open(url, '_blank');
    if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
      console.error("Facebook Login Failed: Popup blocked by the browser.");
    }
  };

  useEffect(() => {
    if (fbRef.current) {
      window.FB?.XFBML.parse(fbRef.current);
    }
  }, [fbRef.current]);

  useEffect(() => {
    let interval;
  
    const checkFbToken = () => {
      const accessCode = Cookies.get("fbAccessCode");
      console.log(accessCode,'fbtokkkk')
      if (accessCode) {
        dispatch(facebookTokenAsync(accessCode));
        clearInterval(interval);
        console.log("FB Token found and dispatched. Stopping interval.");
      }
    };
  
    interval = setInterval(checkFbToken, 1000);
  
    return () => clearInterval(interval); // Cleanup when component unmounts
  }, [dispatch]); 
  
  

  // const handleFacebookResponse = (response) => {
  //   console.log(response, "Facebook response");
  //   if (facebookToken) return;
  //   if (response?.accessToken) {
  //     Cookies.set("fbAccessCode", response?.accessToken, { expires: 1 });
  //     console.log("Before setting facebookToken:", facebookToken);
  //     setFacebookToken(true);
  //     console.log("After calling setFacebookToken(true)");
  //   } else {
  //     toast.error(`Login failed with ${platform}`);
  //   }
  // };

  // useEffect(() => {
  //   console.log(facebookToken, 'tokennn in useEffect')
  //   if (facebookToken) {
  //     setTimeout(() => {
  //       console.log('hereeee')
  //       const storedFbToken = Cookies.get("fbAccessCode");
  //       console.log(storedFbToken, 'storedFbToken')
  //       if (storedFbToken) {
  //         dispatch(facebookTokenAsync(storedFbToken));
  //       }
  //       setFacebookToken(false);
  //     }, 1000)
  //   }
  // }, [facebookToken]);



  useEffect(() => {
    if (fbToken?.status_code == 200) {
      Cookies.set("fbAccessToken", fbToken?.access_token, { expires: 1 });
      toast.success(`Authenticated successfully with ${platform}`);
      setSourceAuthModal(false);
      setSourceModal(false);
      dispatch(clearFbTokenState())
    } else if (fbToken?.status_code == 500) {
      toast.error(`Failed to authenticate with ${platform}`);
      dispatch(clearFbTokenState())

    }
  }, [fbToken?.status_code])

  return (
    <Modal
      open={sourceAuthModal}
      onClose={() => setSourceAuthModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <MDBox
        sx={{
          width: 420,
          bgcolor: "#ffffff",
          p: 3,
          "& button": { borderRadius: "45px" },
          borderRadius: "24px",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            right: "-7px",
            top: "-7px",
            cursor: "pointer",
          }}
          onClick={() => setSourceAuthModal(false)}
        >
          <img src={modalcross} alt="status icon" />
        </div>
        <div>
          <img src={modallogo} alt="status icon" />
          <h2
            id="modal-modal-title"
            style={{
              paddingTop: "1rem",
              paddingBottom: "2rem",
              color: "#318BEC",
              paddingRight: "3.8rem",
              fontSize: "32px",
              fontFamily: "urbanist",
              lineHeight: "42px",
            }}
          >
            Authenticate to access various features
          </h2>
          <div
            style={{
              border: `${platform === "Facebook" ? '' : '2px solid #d4cfc3'}`,
              padding: " 0.2rem 1rem",
              borderRadius: "45px",
              marginBottom: 2,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ overflow: "hidden", border: "1px solid white", cursor: "pointer" }}>
                {platform === "Google" ? (
                  <div style={{ display: "flex" }}>
                    <div>
                      <img
                        src={googleIcon}
                        alt="icon"
                        style={{ marginRight: "15px", marginTop: "10px" }}
                      />
                    </div>
                    <div onClick={handleAuthClick} style={{ marginTop: "7px" }}>
                      Authenticate with Google
                    </div>
                  </div>
                ) : platform === "X" ? (
                  <TwitterLogin
                  // loginUrl="http://localhost:4000/api/v1/auth/twitter"
                  // onFailure={onTwitterFailure}
                  // onSuccess={onTwitterSuccess}
                  // requestTokenUrl="Enter your email and password to login!host:4000/api/v1/auth/twitter/reverse"
                  />
                ) : platform === "Facebook" ? (
                  <div style={{ display: "flex", border: "2px solid rgb(212, 207, 195)", overflow: "hidden",padding:"0.2rem 1rem", borderRadius:"40px" }}>
                  <div>
                    <img
                      src={facebookIcon}
                      alt="icon"
                      style={{ marginTop: "10px", width:"25px" }}
                    />
                  </div>
                  <div onClick={handleFacebookClick} style={{ marginTop: "7px", marginLeft:"10px" }}>
                  Authenticate with Facebook
                  </div>
                </div>
                ) : null}

              </div>
            </div>
          </div>
          <p
            style={{
              fontSize: "14px",
              color: "#7a7a78",
              marginTop: "2rem",
              textAlign: "center",
            }}
          >
            By proceeding, you agree to our{" "}
            <a href="/">
              <span
                className="underline-imp"
                style={{
                  textDecoration: "underline!important",
                  cursor: "pointer",
                  color: "#7a7a78",
                  "&:visited": { textDecoration: "underline!important" },
                  display: "inline",
                }}
              >
                Terms of Use
              </span>
            </a>{" "}
            and confirm you have read our{" "}
            <a href="/">
              <span
                className="underline-imp"
                style={{
                  textDecoration: "underline!important",
                  cursor: "pointer",
                  color: "#7a7a78",
                  display: "inline",
                }}
              >
                Privacy and Cookie Statement
              </span>
            </a>
            .
          </p>
        </div>
      </MDBox>
    </Modal>
  );

};

AuthModal.propTypes = {
  sourceAuthModal: PropTypes.bool.isRequired,
  setSourceAuthModal: PropTypes.func.isRequired,
  setSourceModal: PropTypes.func.isRequired,
  platform: PropTypes.string.isRequired,
};

export default AuthModal;
