import React from 'react';
import { Modal } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { useSelector } from 'react-redux';
import { updateOrganizationSubscription } from 'services/subscription';
import { SubscriptionStatus } from 'constant';
import { toast } from 'react-hot-toast';

const SubscriptionPricing = ({
  visible,
  setVisibility,
  onPriceSelectionCompleted,
}) => {
  const { status, quantity } = useSelector((state) => state.subscription);
  const profileData = useSelector((state) => state.auth.getprofile);
  const [loading, setLoading] = React.useState(false);

  const isRecreatingFirstBusiness =
    status === SubscriptionStatus.ACTIVE && quantity === 0;

  const onClickSubscriptionHandler = async () => {
    const userId = profileData?.userProfile?.id;
    if (!userId) return;

    setLoading(true);
    try {
      await updateOrganizationSubscription(userId);
      toast.success('Subscription Updated Successfully!');
      onPriceSelectionCompleted?.();
    } catch (err) {
      toast.error('Subscription update failed. Please try again.');
    } finally {
      setLoading(false);
      setVisibility(false);
    }
  };

  const getPricingMessage = () => {
    if (isRecreatingFirstBusiness) {
      return 'Recreating your first business this billing cycle incurs a charge!';
    }
    return status === 'trial'
      ? `You will upgrade from a free trial to a paid subscription and you will be charged for ${
          quantity + 1
        } businesses.`
      : 'Add a business subscription to add your new business!';
  };

  if (!visible) {
    return null;
  }

  return (
    <Modal
      open={visible}
      onClose={() => setVisibility(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <MDBox
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: '#fff',
          padding: '20px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
          borderRadius: '8px',
          minWidth: '320px',
        }}
      >
        <MDBox sx={{ position: 'relative', pt: '15px' }}>
          <MDBox>
            <h2
              id='modal-modal-title'
              style={{
                fontFamily: 'urbanist',
                fontSize: '24px',
                fontWeight: 700,
                color: '#333',
                textAlign: 'center',
              }}
            >
              Confirm Subscription
            </h2>
            <p
              id='modal-modal-description'
              style={{
                fontFamily: 'urbanist',
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '30px',
                marginTop: '14px',
                color: '#818181',
                textAlign: 'center',
              }}
            >
              {getPricingMessage()}
            </p>
          </MDBox>

          <MDBox
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: '16px',
              marginTop: '30px',
            }}
          >
            <MDButton
              variant='gradient'
              color='error'
              sx={{
                paddingLeft: '4rem',
                paddingRight: '4rem',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                borderRadius: '45px',
              }}
              onClick={() => setVisibility(false)}
              disabled={loading}
            >
              Cancel
            </MDButton>
            <MDButton
              variant='gradient'
              color='info'
              sx={{
                paddingLeft: '4rem',
                paddingRight: '4rem',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                borderRadius: '45px',
              }}
              onClick={onClickSubscriptionHandler}
              disabled={loading}
            >
              {loading
                ? 'Processing...'
                : isRecreatingFirstBusiness
                ? 'Charge'
                : 'Subscribe'}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Modal>
  );
};

export default SubscriptionPricing;
